
import { selectProductList, updateStatus } from "@/api/request-modules/product";
import useWatchChangeProduct from "@/composables/useWatchChangeProduct";
import { ResponseParams } from "@/types/response";
import { setSession } from "@/utils";
import { CONSTVAR } from "@/utils/enum";
import useGetDemandList from "@/views/iteration/useGetDemandList";
import { demandList } from "@/views/iteration/useMixin";
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import HandleProduct from "@/layout/header/components/handleProduct.vue";
import useCeateOrEditProduct from "@/layout/header/composables/useCeateOrEditProduct";
import { ElMessage, ElMessageBox } from "element-plus";
import useHome from "@/composables/useHomePage";
import useApproval from "@/composables/useApproval";
import useCommon, { ProjectItem } from "@/composables/useCommon";
import { store } from "@/store";
import { MutationType } from "@/store/mutation-types";

const { useUnshelve } = useApproval();
const checkAddress = (rule: Array<unknown>, value: string, callback: (...args: Array<unknown>) => void): void => {
  const reg = /https:\/\/qyapi.weixin.qq.com\/cgi-bin\/webhook\/send\?key=(\w|\d|-){1,}/g;
  if (!value) {
    callback();
    return;
  }
  if (!reg.test(value)) {
    callback(new Error("格式错误，正确格式为 https://qyapi.weixin.qq.com/cgi-bin/webhook/send?key="));
  } else {
    callback();
  }
};
const rules = {
  name: [
    { required: true, message: "请输入产品名称", trigger: "blur" },
    { min: 2, max: 10, message: "长度在 2 到 10 个字符", trigger: "blur" }
  ],
  remark: [
    { required: false, message: "请输入产品描述", trigger: "blur" },
    { min: 0, max: 200, message: "长度在 0 到 200 个字符", trigger: "blur" }
  ],
  notify_url: [
    {
      validator: checkAddress,
      trigger: "blur"
    }
  ]
};
export default defineComponent({
  components: {
    HandleProduct
  },
  setup() {
    const { getProductPermission } = useCeateOrEditProduct();
    const { useHandleProjectStatus } = useHome();
    const { useGetProjectList } = useCommon();
    const productList = computed(() => store.getters.productList);
    const productId = computed(() => store.getters.productId);

    const formRef = ref<(HTMLElement & { resetFields: () => void; clearValidate: () => void; validate: (...args: Array<any>) => void }) | null>(null);
    const visible = ref(false);
    // @ts-ignore
    const dialogParams = reactive({
      // 项目名称
      name: "",
      // 备注
      remark: "",
      // 编辑的id
      id: 0,
      // 项目代号
      code: "",
      // 提醒地址
      notify_url: "",
      // 是否需要审批
      is_approval: 0,
      // 是否需要邮件报告
      is_report: 0,
      // 是否需要周会汇报
      week_report: 0,
      // 项目logo
      logo: "",
      // 当前状态
      state: 0,
      // 团队名称
      team_name: "",
      // 团队id
      team_id: 0
    });

    const isShowDelBtn = ref(false);

    const handleUrl = computed(() => {
      const { notify_url } = dialogParams;
      if (!notify_url.includes("，") && notify_url) {
        return notify_url.split(",");
      }
      return [notify_url];
    });

    onMounted(() => {
      watch(
        () => productList.value,
        (newValue) => {
          if (newValue.length) {
            getProduct();
            getPermission();
          }
        },
        {
          deep: true
        }
      );
      // 获取项目的权限，是否显示删除按钮
      getPermission();
    });
    const getPermission = async () => {
      isShowDelBtn.value = await getProductPermission(productId.value);
    };

    const getProduct = () => {
      const productDetail = (productList.value as ProjectItem[]).find((v) => v.id === parseInt(productId.value));

      productDetail &&
        Object.keys(dialogParams).forEach((key) => {
          const k = key as keyof typeof productDetail;
          // @ts-ignore
          dialogParams[k] = productDetail![k];
        });
    };

    useWatchChangeProduct(
      () => {
        /** */
      },
      () => {
        if (!productList.value.length) return;
        getProduct();
        getPermission();
      }
    );
    const hideBtnbackground = (e: any) => {
      if (e) {
        let target = e.target;
        if (target.nodeName == "SPAN" || target.nodeName == "I") {
          target = e.target.parentNode;
        }
        target.blur();
      }
    };
    //#region 点击保存
    const handleConfirmSave = (e: any) => {
      hideBtnbackground(e);
      visible.value = true;
    };
    //#endregion
    //#region 删除
    const getDemandList = useGetDemandList();
    const router = useRouter();
    const handleDelete = async (e: any) => {
      hideBtnbackground(e);

      const confirm = await ElMessageBox.confirm("确认删除该项目吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "el-button--danger-box"
      });

      if (confirm !== "confirm") return;

      updateStatus([
        {
          status: 0,
          id: productId.value
        }
      ]).then(() => {
        // 重新获取当前产品列表，更新
        selectProductList<ResponseParams.ResponseDataSuccess>().then((res: Record<string, any>) => {
          store.commit(MutationType.updateProductList, res.data);
          // 设置项目缓存
          store.commit(MutationType.updateProductInfo, productList.value[0]);
          store.commit(MutationType.updateProductId, productList.value[0].id);
          router.replace({
            query: Object.assign(
              { ...router.currentRoute.value.query },
              {
                productId: productId.value
              }
            )
          });
          demandList.value = [];
          getDemandList(productId.value).then((res) => {
            demandList.value = res;
            const cacheObj = [] as Array<any>;
            productList.value.forEach((v: Record<string, any>) => {
              cacheObj.push({
                projectId: v.id,
                iterationId: null,
                yapi_default_iteration_id: v.yapi_default_iteration_id
              });
            });
            setSession("cacheProject", JSON.stringify(cacheObj));
          });
          return true;
        });
      });
    };

    // 结项和搁置
    const handleProject = async (type: number, e?: any) => {
      hideBtnbackground(e);

      let isSucc: boolean | null = false;

      const val = type === 3 ? "是否确认将项目变更为搁置状态" : type === 4 ? "是否确认将项目变更为结项状态" : "是否确认将项目取消搁置";
      const typeStr = await ElMessageBox.confirm(`${val}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      if (typeStr === "confirm") {
        if (type === 1) {
          isSucc = await handleShelve();
        } else {
          isSucc = await useHandleProjectStatus({ product_id: productId.value, state: type });
        }

        if (!isSucc) return;
      }
      ElMessage.success("操作成功");

      await getProjectList();
      getProduct();
      getPermission();
    };

    // 取消搁置
    const handleShelve = async () => {
      const isSucc = await useUnshelve(productId.value);
      return isSucc;
    };
    // 获取新的项目list
    const getProjectList = async () => {
      const data = await useGetProjectList();
      if (data) {
        store.commit(MutationType.updateProductList, data);
        return true;
      }
      return false;
    };

    const onUpdateInfo = () => {
      getProjectList();
    };
    //#endregion
    return {
      onUpdateInfo,
      getProjectList,
      handleProject,
      dialogParams,
      rules,
      handleConfirmSave,
      formRef,
      handleDelete,
      visible,
      handleUrl,
      isShowDelBtn,
      CONSTVAR
    };
  }
});
